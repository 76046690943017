export const OpenBlock = () => {
  $('.js-button-open').on('click', ({ target }) => {
    $(target)
      .closest('.js-opened-block')
      .toggleClass('opened')
      .removeClass('opened-email');
  });

  $('.js-opened-email-form').on('click', ({ target }) => {
    $(target)
      .closest('.js-opened-block')
      .addClass('opened-email');
  });
};
