export const Modal = () => {
  const body = document.querySelector('body');

  $('.modal').each(function () {
    const modal = $(this);
    const closeButtons = modal.find('.js-modal-close');
    const contentWrapper = modal.find('.modal__block');

    const closeModal = () => {
      modal.removeClass('open');
      body.classList.remove('open-modal');
    };

    closeButtons.each(function() {
      $(this).on('click', closeModal);
    });

    modal.on('click', closeModal);
    contentWrapper.on('click', e => e.stopPropagation());
  });

  $('.js-modal-trigger').each(function () {
    const trigger = $(this).attr('data-modal-trigger');
    const modal = $(`[data-modal-name=${trigger}]`);

    $(this).on('click', function () {
      modal.addClass('open');
      body.classList.add('open-modal');
    });
  });
};
