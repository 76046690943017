import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

export const OwlCaruselParameters = () => {
  const checkWidth = $(document).width();

  $('.main-slider').owlCarousel({
    items: 1,
    loop: false,
    nav: true,
    dots: false,
    touchDrag: false,
    mouseDrag: false,
    navSpeed: 800,
    animateOut: 'fadeOut'
  });

  $('.owl-benefits').owlCarousel({
    loop: false,
    items: 1,
    stagePadding: 15
  });

  setTimeout(() => {
    $('.slider-content').owlCarousel({
      items: 1,
      loop: true,
      nav: true,
      dots: true,
      touchDrag: false,
      mouseDrag: false,
      navSpeed: 800,
      dotsSpeed: 800
    });
  }, 100);

  function initSliderCards() {
    const $sliderCards = $('.slider-cards');

    if (checkWidth < 768) {
      $sliderCards.owlCarousel('destroy');
      $sliderCards.removeClass('owl-carousel');
    } else {
      $sliderCards.owlCarousel({
        items: 1,
        loop: true,
        nav: true,
        dots: false,
        responsive : {
          768 : {
            items: 2,
            margin: 24
          },
          1210 : {
            items: 3,
            margin: 24
          }
        }
      });
      $sliderCards.removeClass('scroll-wrap');
    }
  }

  $(document).ready(initSliderCards);
  $(window).resize(initSliderCards);
};
