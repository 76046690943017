export const Filter = () => {
  const filterSticky = () => {
    const filterLetter = $('.block-sticky');

    if (filterLetter.length) {
      const windowWidth = $(window).width();
      const windowPosition = $(window).scrollTop();

      if (windowPosition >= (filterLetter.offset().top) - 83) {
        filterLetter.addClass('sticky').css('margin-left', `-${(windowWidth - $('.container').width()) / 2}px`).width(windowWidth - 32);
      } else {
        filterLetter.removeClass('sticky').css('margin-left', '-16px').width('auto');
      }
    }
  };

  $(window).on('load resize scroll',function(){
    filterSticky();
  });

  $('.js-btn-filter').each(function () {
    const $this = $(this);
    const trigger = $this.attr('data-filter-trigger');
    const input = $(`[data-filter-input=${trigger}]`);

    $this.on('click', function () {
      const selected = input.find('option:selected');

      if (input.find(':selected')) {
        $(selected).prop('selected', false);
        input.find('option:first-child').prop('selected', true).end().trigger('chosen:updated');
      }

      $this.detach();
    });
  });
};
