export const BtnLoading = () => {
  $('form').each(function () {
    const $this = $(this);

    $this.on('submit', function () {
      $this.find('.js-loading').removeClass('hidden');
      $this.find('.js-submit-btn').addClass('hidden');
    });
  });
};
