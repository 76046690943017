export const ScrollTo = () => {
  $('.js-scroll-to').click(function (event) {
    event.preventDefault();
    const href = $(this).attr('href');
    const target = $(href);
    const top = (target.offset().top) - 120;
    $('html,body').animate({
      scrollTop: top
    }, 1000);
  });
};
