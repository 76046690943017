export const CompanyStickyElement = () => {
  const menuWrap = $('.mobile-menu');
  const menuTriger = $('.mobile-menu-triger');

  $(document).scroll(function () {
    if (menuWrap.length > 0) {
      if ($(this).scrollTop() >= 180) {
        menuWrap.addClass('active');
        menuTriger.addClass('active');
      } else {
        menuWrap.removeClass('active');
        menuTriger.removeClass('active');
      }
    }
  });
};
