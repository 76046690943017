export const ScrollToTop = () => {
  const $window = $(window);
  const scrollToTopBtn = $('.js-scroll-to-top');

  const handleShowScrollToTopBtn = () => {
    if ($window.scrollTop() > $window.height() / 2) {
      scrollToTopBtn.addClass('visible');
    } else {
      scrollToTopBtn.removeClass('visible');
    }
  };

  handleShowScrollToTopBtn();

  $window.on('scroll', () => handleShowScrollToTopBtn());

  scrollToTopBtn.on('click', () => {
    $('html,body').animate({
      scrollTop: 0
    }, 1000);
  });
};
