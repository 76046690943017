import 'jquery-ui/ui/widgets/autocomplete';

export const Autocomplete = () => {
  const elements = $('[data-autocomplete]');

  function build(element) {
    const form = element.is('form') ? element : element.parents('form:first');
    const input = form.find('.js-autocomplete-target');
    const url = element.data('search-url');
    const params = element.data('search-params');
    const appendTo = element.data('append-to') || 'body';
    const token = document.querySelector('meta[name=\'csrf-token\']') ? document.querySelector('meta[name=\'csrf-token\']').getAttribute('content') : '';
    const options = {
      appendTo,
      source: (request, responce) => {
        $.ajax({
          beforeSend: requestParams => {
            requestParams
              .setRequestHeader('Accept', 'application/vnd.api+json')
              .setRequestHeader('Content-Type', 'application/vnd.api+json')
              .setRequestHeader('X-CSRF-TOKEN', token);
          },
          dataType: 'json',
          url: url + params + request.term,
          success: ({ data }) => responce(data.map(({ attributes }) => attributes.name))
        });
      },
      open: () => {
        $(appendTo).fadeIn(200);
      },
      close: () => {
        $(appendTo).fadeOut(200);
      }
    };

    input.autocomplete(options);
  }

  if (elements.length > 0) {
    elements.each(function () {
      build($(this));
    });
  }
};
