export const ProfessionsBlock = () => {
  $('.professions').each(function () {
    const $this = $(this);
    const height = $this.outerHeight();

    if (height >= 275) {
      $this.addClass('js-opened-block');
    } else {
      $this.removeClass('js-opened-block');
    }
  });
};
