/* eslint no-underscore-dangle: "off", no-multi-assign: "off" */

import jQuery from 'jquery';
import Rails from 'rails-ujs';
import 'select2';
import 'select2/dist/css/select2.css';
import Turbolinks from 'turbolinks';

// lazy load for images
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// Components
import { Navigation } from './components/Navigation';
import { Dropdown } from './components/Dropdown';
import { OpenBlock } from './components/OpenBlock';
import { OwlCaruselParameters } from './components/OwlCaruselParameters';
import { SelectForm } from './components/SelectForm';
import { CompanyStickyElement } from './components/CompanyStickyElement';
import { Filter } from './components/Filter';
import { ScrollTo } from './components/ScrollTo';
import { InitMap } from './components/InitMap';
import { Modal } from './components/Modal';
import { ProfessionsBlock } from './components/ProfessionsBlock';
import { BtnLoading } from './components/BtnLoading';
import { Landing } from './components/Landing';
import { Chosen } from './components/Chosen';
import { PaginationScroll } from './components/PaginationScroll';
import { PasswordVisible } from './components/PasswordVisible';
import { FlashMessages } from './components/FlashMessages';
import { Autocomplete } from './components/Autocomplete';
import { CorporateModal } from './components/CorporateModal';
import { BenefitsTabs } from './components/BenefitsTabs';
import { PackageTabs } from './components/PackageTabs';
import { Collapse } from './components/Collapse';
import { Banners } from './components/Banners';
import { ConditionalInput } from './components/ConditionalInput';
import { ValidateOnlyNumbers } from './components/ValidateOnlyNumbers';
import { ScrollToTop } from './components/ScrollToTop';
import { Popup } from './components/Popup';
import { Survey } from './components/Survey';

window.$ = window.jQuery = jQuery;

Turbolinks.start();
if (!window._rails_loaded) Rails.start();

function loadWithTurbolinks() {
  Dropdown();
  Navigation();
  OpenBlock();
  SelectForm();
  CompanyStickyElement();
  Filter();
  OwlCaruselParameters();
  ScrollTo();
  InitMap();
  Modal();
  ProfessionsBlock();
  BtnLoading();
  Landing();
  Chosen();
  PaginationScroll();
  PasswordVisible();
  FlashMessages();
  Autocomplete();
  CorporateModal();
  Collapse();
  BenefitsTabs();
  PackageTabs();
  Banners();
  ConditionalInput();
  ValidateOnlyNumbers();
  ScrollToTop();
  Popup();
  Survey();
}

$(document).on('turbolinks:load', loadWithTurbolinks);
