export const ValidateOnlyNumbers = () => {
  const input = $('.js-only-numbers');
  let prevValue = input.val();

  input.on('input', e => {
    const { value, attributes } = e.target;

    if (prevValue !== value) {
      if (/^(\s*|[1-9][0-9]*)$/.test(value) && +value >= attributes.min.value && +value <= attributes.max.value || value === '') {
        prevValue = value;
      } else {
        input.val(prevValue);
      }
    }
  });
};

window.ValidateOnlyNumbers = () => ValidateOnlyNumbers();
