/* eslint no-undef: "off" */

const loadGoogleMapsApi = require('load-google-maps-api');

export const InitMap = () => {
  const mapItems = $('.js-init-map');

  if (mapItems.length > 0) {
    const gKey = $(mapItems).data('gkey');
    loadGoogleMapsApi({
      key: gKey
    }).then(function (googleMaps) {
      mapItems.each((index, item) => {
        const id = $(item).attr('id');
        const lat = $(item).data('lat');
        const lng = $(item).data('lng');
        const iconBase = $(item).data('marker');
        const iconLogo = $(item).data('logo');

        const uluru = { lat, lng };

        const map = new googleMaps.Map(document.getElementById(`${id}`), {
          zoom: 17,
          center: uluru,
          disableDefaultUI: true
        });

        const iconLogoBg = document.getElementById(`${id}`).dataset.logobg;

        const marker = new googleMaps.Marker({
          position: uluru,
          map,
          icon: iconBase,
          zIndex: 3
        });

        if ($(item).data('logo')) {
          marker.flag = new google.maps.Marker({
            icon: {
              url: iconLogo,
              size: new google.maps.Size(32, 32),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(16, 66)
            },
            zIndex: 2
          });

          marker.flagBg = new google.maps.Marker({
            icon: {
              url: iconLogoBg,
              size: new google.maps.Size(32, 32),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(16, 66)
            },
            zIndex: 1
          });

          marker.flag.bindTo('position', marker);
          marker.flag.bindTo('map', marker);

          marker.flagBg.bindTo('position', marker);
          marker.flagBg.bindTo('map', marker);
        }
      });
    });
  }
};
