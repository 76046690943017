export const Dropdown = () => {
  const dropdown = $('.js-dropdown');
  const userMenu = $('.js-user-menu');
  const userMenuHiddenBlock = $('.js-user-menu .dropdown__list');
  const $window = $(window);

  dropdown.each(function () {
    const $this = $(this);
    $this.on('click', () => {
      $this.toggleClass('active');
    });
    if ($window.width() > 1210) {
      $window.on('mouseup touchstart', function (e) {
        if (!$this.has(e.target).length) {
          return $this.removeClass('active');
        }
      });
    }
  });

  userMenu.on('click', function () {
    if ($window.width() < 1210) {
      userMenu.parents($('.navigation__wrap')).stop().animate({
        scrollTop: userMenuHiddenBlock.innerHeight()
      }, 500);
    }
  });

  // refactor this later delete this part
  const dropdown_legacy = $('.drop-head');

  dropdown_legacy.on('click', function () {
    $(this).parent().toggleClass('active');
  });
};
