export const SelectForm = () => {
  $('.styled-select').each(function () {
    const $this = $(this),
      numberOfOptions = $(this).children('option').length;

    if (!$this.hasClass('initialized')) {
      $this.addClass('s-hidden');
      $this.wrap('<div class="dropdown dropdown_border"></div>');
      $this.after('<div class="dropdown__button"></div>');
    }

    const $styledSelect = $this.next('div.dropdown__button');

    if (!$this.hasClass('initialized')) {
      $styledSelect.text($this.children('option').eq(0).text());
      $('<ul />', { 'class': 'dropdown__list' }).insertAfter($styledSelect);
    }

    const $list = $styledSelect.next('ul.dropdown__list');

    if (!$this.hasClass('initialized')) {
      for (let i = 0; i < numberOfOptions; i++) {
        $('<li />', {
          text: $this.children('option').eq(i).text(),
          rel: $this.children('option').eq(i).val()
        }).appendTo($list);
      }
    }

    const $listItems = $list.children('li');

    $styledSelect.click(function (e) {
      e.stopPropagation();
      if ($(this).hasClass('active')) {
        $(this).removeClass('active').next('ul.dropdown__list').toggle();
        return true;
      };
      $('div.dropdown__button.active').each(function () {
        $(this).removeClass('active').next('ul.dropdown__list').hide();
      });
      $(this).toggleClass('active').next('ul.dropdown__list').toggle();
    });

    $listItems.click(function (e) {
      e.stopPropagation();
      $styledSelect.text($(this).text()).removeClass('active');
      $this.val($(this).attr('rel'));
      $list.hide();
      $this.trigger('change');
    });

    $(document).click(function () {
      $styledSelect.removeClass('active');
      $list.hide();
    });

    if ($this.find(':selected')) {
      $styledSelect.text($this.find(':selected').text());
    }

    $this.addClass('initialized');
  });

  $('.js-submit-on-change').each(function () {
    const $this = $(this);

    $this.on('change', (e) => {
      $(e.target).closest('form').submit();
    });
  });

  const removeDuplicateOfSelect2 = el => {
    if (el.data('select2') === undefined && el.next().hasClass('select2-container')) el.next().remove();
  };

  const selectSingle = () => {
    $('.js-select').each(function () {
      const el = $(this);
      const placeholder = el.data('placeholder');

      removeDuplicateOfSelect2(el);

      el.select2({
        placeholder: placeholder || ''
      });
    });
  };

  const selectMulti = () => {
    $('.js-multi-select').each(function () {
      const el = $(this);
      removeDuplicateOfSelect2(el);

      el.select2({ theme: 'multi' });
    });
  };

  const selectAsync = () => {
    $('.js-select-async').each(function () {
      const el = $(this);
      const token = document.querySelector('meta[name=\'csrf-token\']') ? document.querySelector('meta[name=\'csrf-token\']').getAttribute('content') : '';

      removeDuplicateOfSelect2(el);

      const $select2 = el.select2({
        minimumInputLength: 3,
        ajax: {
          delay: 250,
          url: el.data('url'),
          dataType: 'json',
          headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
            'X-CSRF-TOKEN': token
          },
          data: params => el.data('params') + params.term,
          processResults: ({ data }) => ({
            results: data.map(({ attributes, id }) => ({ id, text: attributes.name }))
          })
        }
      });

      $select2.data('select2').$dropdown.addClass(`select-async ${el.data('classes') || ''}`);
    });
  };

  selectSingle();
  selectMulti();
  selectAsync();

  window.selectSingle = () => selectSingle();
  window.selectMulti = () => selectMulti();
  window.selectAsync = () => selectAsync();
};
