export const BenefitsTabs = () => {
  const item = $('.benefits-item-js');

  item.click(function () {
    const tab_id = $(this).attr('data-tab');

    item.removeClass('active');
    $('.show-item-js').removeClass('active');

    $(this).addClass('active');
    $(`#${tab_id}`).addClass('active');
  });
};
