export const PasswordVisible = () => {
  $('.form__visible-pass').each(function () {
    const $this = $(this);

    $this.on('click', () => {
      const input = $this.parent().find('input');
      if ($(input).attr('type') === 'text') {
        $(input).prop({ type: 'password' });
      } else {
        $(input).prop({ type: 'text' });
      }
    });
  });
};
